<template>
  <div class="main-container">
    <el-header>
      <Header />
    </el-header>
    <el-main>
      <div class="notice animated fadeInUp">
        <i class="iconfont icon-a-labatongzhitixing"></i>
        <div class="notice-content">{{ notice }}</div>
      </div>
      <Top />
      <Main />
    </el-main>
  </div>
</template>

<script>
import Header from "./components/header";
import Top from "./components/top";
import Main from "./components/main";
import { mapState } from "vuex";
export default {
  name: "index",
  components: {
    Header,
    Top,
    Main,
  },
  computed: {
    ...mapState({
      notice: (state) => state.settings.configs.notice,
    }),
  },
};
</script>

<style scoped>
.main-container {
  position: relative;
}

.el-header {
  position: relative;
  margin-bottom: 1rem;
  object-fit: cover;
  border: none;
  filter: brightness(0.9);
  height: 100%;
}

.el-main {
  max-width: 900px;
  margin: 0 auto;
  overflow: visible;
}

.el-main .notice {
  padding: 0.5rem;
  border: 2px dashed var(--shadow-color);
  color: var(--text-color);
  position: relative;
  display: block;
  border-radius: 0.5rem;
  width: 100%;
  height: auto;
  box-sizing: border-box;
}

.el-main .notice .notice-content {
  display: initial;
  vertical-align: middle;
  margin-left: 5px;
  letter-spacing: 3px;
}
</style>
