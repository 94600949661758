<template>
  <div class="cover">
    <el-image fit="cover" :src="navInfo.cover">
      <template #placeholder>
        <img style="height: 100%" src="@/assets/image/loading.gif" />
      </template>
      <template #error>
        <div class="image-slot">
          <i class="fa fa-image"></i>
        </div>
      </template>
    </el-image>
  </div>
  <div class="bread visible">
    <div class="logo">
      <img :src="logoUrl" />
    </div>
    <p class="title">
      <span class="title-head animated fadeInRight">『</span>
      <span class="title-text animated fadeInDown">{{ blogName }}</span>
      <span class="title-end animated fadeInLeft">』</span>
    </p>
    <p class="sub-title">
      <span class="animated fadeInUp" style="">{{ description }}</span>
    </p>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "header",
  computed: {
    ...mapState({
      logoUrl: (state) => state.settings.configs.logoUrl,
      blogName: (state) => state.settings.configs.blogName,
      description: (state) => state.settings.configs.description,
    }),
  },
  data() {
    return {
      navInfo: {},
    };
  },
  created() {
    this.getHomeInfo();
  },
  methods: {
    async getHomeInfo() {
      const code = this.$route.name;
      const menus = this.$store.getters.menus
      menus.forEach((item) => {
        if (item.code == code) {
          this.navInfo = item
        }
      })
    },
  },
};
</script>

<style scoped>
.cover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  border: none;
  text-align: center;
}

.cover >>> .el-image {
  width: 100%;
  height: 30rem;
  object-fit: cover;
  border: none;
}

.bread {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: #fff;
  height: 30rem;
  text-shadow: 0 0.1875rem 0.3125rem #1c1f21;
  opacity: 0;
  border: none;
}

.bread .logo {
  width: 104px;
  height: 104px;
  padding: 2px;
  border: 2px solid #eee;
  border-radius: 50%;
  margin-bottom: 1rem;
  z-index: 99;
  -webkit-transition: -webkit-transform 0.2s ease-out;
}

.bread .logo:hover {
  transform: rotate(360deg);
  box-shadow: 0 1px 30px 4px var(--shadow-color);
  transform: rotateZ(360deg);
}

.bread .logo img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: all 0.3s;
}

.bread p.title {
  position: relative;
  font-family: "BiaoSong", sans-serif;
  font-size: 3.2em;
  line-height: 1.2;
  z-index: 100;
}

@media (max-width: 767px) {
  .bread p.title {
    font-size: 1.5em;
  }
}

.bread p span {
  display: inline-block;
  animate-duration: 2s;
}

.bread p.sub-title {
  position: relative;
  margin: 1rem 0 0 0;
  font-family: sans-serif;
  font-size: 18px;
  animation: slowUp 1s;
  transition: opacity 0.2s, slowUp 0.4s;
  z-index: 99;
}
</style>
