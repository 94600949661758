import request from "@/utils/request";

export async function listArticle(curPage, pageSize) {
  return request({
    url: "/pageArticle",
    method: "post",
    params: {
      curPage,
      pageSize,
    },
  });
}

export async function getArticleLink(year, month, day, title, authCode) {
  return request({
    url: "/" + year + "/" + month + "/" + day + "/" + title + "/",
    method: "get",
    params: {
      authCode,
    }
  });
}

export async function getArticleCustomLink(link, authCode) {
  return request({
    url: "/" + link,
    method: "get",
    params: {
      authCode
    }
  });
}

export async function getArticleTop() {
  return request({
    url: "/getArticleTop",
    method: "get",
  });
}

export function praiseArticle(articleId) {
  return request({
    url: "/praiseArticle",
    method: "get",
    params: {
      articleId,
    },
  });
}

export async function listArchives(data) {
  const curPage = data.curPage;
  const pageSize = data.pageSize;
  return request({
    url: "/listArchives",
    method: "get",
    params: {
      curPage,
      pageSize,
    },
  });
}

export function commentArticle(data) {
  return request({
    url: "/commentArticle",
    method: "post",
    data,
  });
}

export function getCommentByArticleId(data) {
  const id = data.id;
  const curPage = data.curPage;
  const pageSize = data.pageSize;
  return request({
    url: "/getCommentByArticleId",
    method: "post",
    params: {
      id,
      curPage,
      pageSize,
    },
  });
}

export function praiseComment(commentId) {
  return request({
    url: "/praiseComment",
    method: "post",
    params: {
      commentId,
    },
  });
}
