<template>
  <div class="top-container">
    <h1 class="top-title">
      <i class="iconfont icon-qizhi"></i>
      <span> 置顶内容</span>
    </h1>
    <div
      class="top-content"
      v-for="(article, index) in topArticle"
      :key="index"
    >
      <div class="top-item fadeInUp animated visible">
        <i class="iconfont icon-top"></i>
        <a href="javascript: void(0)" @click="linkToArticle(article)">
          <div class="img">
            <el-image :src="article.coverUrl">
              <template #placeholder>
                <img style="width: 100%" src="@/assets/image/loading.gif" />
              </template>
              <template #error>
                <div class="image-slot">
                  <i class="fa fa-image"></i>
                </div>
              </template>
            </el-image>
          </div>
          <div class="info">
            <h3>{{ article.title }}</h3>
            <p>分类：{{ article.categoryVO.name }}</p>
          </div>
        </a>
      </div>
    </div>
  </div>
  <el-empty
    v-if="topArticle.length == 0"
    :image-size="200"
    description="没有置顶文章"
  ></el-empty>
</template>
<script>
import { getArticleTop } from "@/api/article";
export default {
  name: "top",
  data() {
    return {
      topArticle: [],
    };
  },
  created() {
    this.getArticleTop();
  },
  methods: {
    async getArticleTop() {
      await getArticleTop().then((res) => {
        if (res.code == 200) {
          this.topArticle = res.data;
        }
      });
    },
    linkToArticle(item) {
      if (item.customLink) {
        this.$router.push("/article/" + item.customLink + ".html");
      } else {
        this.$router.push("/article/" + item.link);
      }
    },
  },
};
</script>
<style scoped>
.top-container {
  width: 100%;
  height: auto;
  margin-top: 40px;
  position: relative;
  z-index: 99;
}
.top-container .top-title {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 400;
  padding-bottom: 5px;
  margin-bottom: 30px;
  border-bottom: 1px dashed var(--shadow-color);
}
.top-container .top-content {
  display: inline-block;
  margin-bottom: 20px;
}
.top-container .top-content:not(:last-child) {
  margin-right: 20px;
}
.top-container .top-content .top-item {
  height: 160px;
  position: relative;
  box-shadow: 1px 1px 3px rgb(0 0 0 / 30%);
  overflow: hidden;
  border-radius: 10px;
  box-sizing: border-box;
}
.top-container .top-content .top-item:hover {
  box-shadow: 0 1px 20px -6px #bcc3d4;
}
.top-container .top-content .top-item .el-image {
  height: 160px;
  width: 270px;
  object-fit: cover;
  transform: scale(1);
  transition: transform 0.5s ease 0s;
}
.top-container .top-content .top-item:hover .el-image {
  transform: scale(1.05);
}
.top-container .top-content .top-item .info {
  position: absolute;
  height: 40px;
  width: 100%;
  top: 45px;
  text-align: center;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
}
.top-container .top-content .top-item .info h3 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 5px;
}
.top-container .top-content .top-item .info p {
  font-style: italic;
  font-size: 12px;
  position: relative;
  color: #fff;
  padding: 20px 20px 20px;
  opacity: 1;
  transform: translateY(-0px);
  transition: all 0.5s;
  text-align: center;
  opacity: 0;
}
.top-container .top-content .top-item:hover .info p {
  opacity: 1;
  transform: translateY(-10px);
}
.top-container .top-content .top-item .info h3:hover {
  color: var(--primary-color);
}
@media screen and (max-width: 768px) {
  .top-container .top-content {
    display: block;
    margin: 20px auto !important;
  }
  .top-container .top-content .top-item .el-image {
    height: 209px;
    width: 100%;
  }
}
.image-slot {
  text-align: center;
  font-size: 30px;
  line-height: 160px;
}
.icon-top {
  position: absolute;
  z-index: 99;
  font-size: 50px;
  top: -26px;
}
</style>
